// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctt-ui-button {
    padding: 10px;
    border: none;
    background-color: var(--color-green);
    color: #fff;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,oCAAoC;IACpC,WAAW;IACX,eAAe;AACnB","sourcesContent":[".ctt-ui-button {\n    padding: 10px;\n    border: none;\n    background-color: var(--color-green);\n    color: #fff;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
